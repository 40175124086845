@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Aldrich&family=Orbitron:wght@400..900&family=Rajdhani:wght@300;400;500;600;700&display=swap");
/* styles.css */

/* Global Styles */
body {
  font-family: "Rajdhani";
  background-image: linear-gradient(
    to right,
    #ffffff,
    #f8f7ff,
    #eff0ff,
    #e4e9ff,
    #d8e3ff,
    #cbe3ff,
    #bce3ff,
    #ace3ff,
    #97e9ff,
    #81f0ff,
    #6cf6fc,
    #5ffbf1
  );
  color: #fff;
}

.h1 {
  font-family: "Orbitron";
}

.h2,
h3 {
  font-family: "Rajdhani";
}

.text-gradient {
  background: #1D2A47; background: linear-gradient(135deg, #1D2A47, #33BCCF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.primary-color {
  background-color: linear-gradient(to right, #dd32f3, #ce90cb);
  -webkit-background-clip: text;
  color: transparent;
}

.bg-primary-color {
  background-color: linear-gradient(to right, #6b2b72, #a5079d);
}

.blur-bg {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.ptx2 {
  color: #6fbcf0;
}

.ptx {
  color: #1d2947;
}

.ctext {
  color: #76839d;
}

.disable-scroll {
  overflow: hidden;
}

.a {
  text-decoration: underline;
  cursor: pointer;
}
.a:hover {
  text-decoration: none;
  cursor: pointer;
}

.btn {
  cursor: pointer;
  text-align: center;
  background-size: 300% 100%;
  color: #1d2947;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  background-position: 100% 0;
  background-image: linear-gradient(
    to right,
    #e6effe,
    #f0f4f7,
    #cbe3ff,
    #e6effe
  );
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: #6fbcf0;
  width: 150px;
}

.card {
  background-color: rgba(255, 255, 255, .15);   
  border-radius: 40px;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
}

.card:hover {
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
}

.footer{
  background: #1D2A47; background: linear-gradient(135deg, #1D2A47, #33BCCF);
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  backdrop-filter: blur(5px);
  }